'use client';
import { buttonVariants } from '@components/Button';
import ContentfulImage from '@components/ContentfulImage';
import { TestimonialsFragment } from '@queries/fragments/testimonials.fragment';
import { FragmentOf } from 'gql.tada';
import { twMerge } from 'tailwind-merge';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTrigger,
} from './Dialog';
import RichText from './RichText';
import { Wrap } from './Wrap';

const TestimonialDialog = ({
	item,
	children,
}: {
	item: FragmentOf<typeof TestimonialsFragment>;
	children: React.ReactNode;
}) => (
	<Dialog>
		<DialogTrigger asChild className="h-full cursor-pointer text-left">
			{children}
		</DialogTrigger>
		<DialogContent>
			<DialogHeader>
				<div className="mb-4 flex items-center space-x-3">
					{item.avatar?.url && (
						<ContentfulImage
							className="size-16 shrink-0 rounded-md shadow-lg"
							src={item.avatar.url}
							width={86}
							height={86}
							alt={item.name || 'Avatar'}
						/>
					)}
					<div>
						<h3 className="mb-0.5 text-xl font-semibold">{item.name}</h3>
						<p className="text-base font-medium">{item.jobTitle}</p>
					</div>
				</div>
				<DialogDescription>
					<blockquote>
						<RichText
							content={item.content}
							className="inline font-medium dark:prose-invert"
							prose="prose md:prose-lg"
						/>
					</blockquote>
				</DialogDescription>
			</DialogHeader>
		</DialogContent>
	</Dialog>
);

const TestimonialCard = ({
	item,
}: {
	item: FragmentOf<typeof TestimonialsFragment>;
}) => (
	<Wrap
		key={item.sys?.id}
		condition={item.content !== null}
		wrapper={(children) => (
			<TestimonialDialog item={item}>{children}</TestimonialDialog>
		)}>
		<article className="not-prose nice-transition group relative h-full shrink-0 cursor-pointer rounded-md border bg-cream/60 p-5 transition-all hover:border-gray-300 hover:bg-cream hover:shadow-md dark:border-cream/40 dark:bg-transparent dark:hover:bg-black">
			<div className="mb-4 flex items-center space-x-3">
				{item.avatar?.url && (
					<ContentfulImage
						className="size-14 shrink-0 rounded-md shadow-lg"
						src={item.avatar.url}
						width={64}
						height={64}
						alt={item.name || 'Avatar'}
					/>
				)}
				<div>
					<h3 className="mb-0.5 text-sm font-semibold">{item.name}</h3>
					<p className="text-sm font-medium">{item.jobTitle}</p>
				</div>
			</div>
			<div className="space-y-4">
				<blockquote className="line-clamp-4">
					<RichText
						content={item.content}
						prose="prose"
						className="inline font-medium dark:prose-invert"
					/>
				</blockquote>
				<div
					className={twMerge(
						buttonVariants({ variant: 'outline', size: 'sm' }),
						'transition-all group-hover:-translate-y-0.5 group-hover:shadow-md'
					)}>
					Read Testimonial
				</div>
			</div>
		</article>
	</Wrap>
);
export default TestimonialCard;
